import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { Content } from "page_components/not_found"

const NotFoundPage = () => {
  const breadcrumbs_data = [
    {
      name: "Strona nie istnieje",
      href: "/404",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Strona nie istnieje",
        description: "",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Strona nie istnieje" />
      <Content />
    </Layout>
  )
}

export default NotFoundPage
