import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Content = () => {
  return (
    <section className="not-found">
      <h2 className="not-found__large">404</h2>
      <p className="not-found__info">
        Przepraszamy, podana strona nie została znaleziona.
      </p>
      <Button type="button" to="/" className="button-dark-green">
        Przejdź na stronę główną
      </Button>
    </section>
  )
}

export default Content
